import React, { Component, PureComponent, Fragment } from 'react';
import MediaButton from '../../components/MediaButton';
import { ReactComponent as Headphones } from '../../icons/headphones.svg';
import { ReactComponent as PlayButton } from '../../icons/play-arrow.svg';
import { ReactComponent as Kanguka } from '../../icons/kanguka-logo.svg';
import { ReactComponent as FacebookButton } from '../../icons/icons8-facebook.svg';
import { ReactComponent as MediumButton } from '../../icons/icons8-medium.svg';
import { ReactComponent as SpotifyButton } from '../../icons/icons8-spotify.svg';
import { ReactComponent as TwitterButton } from '../../icons/icons8-twitter.svg';
import { ReactComponent as YoutubeButton } from '../../icons/icons8-youtube.svg';
import { ReactComponent as InstagramButton } from '../../icons/icons8-instagram.svg';
import { ReactComponent as PodcastCZButton } from '../../icons/icons8-podcast_cz.svg';
import { ReactComponent as LinkedInButton } from '../../icons/icons8-linkedin.svg';
import { ReactComponent as TikTokButton } from '../../icons/icons8-tiktok.svg';
import PropTypes from 'prop-types';
import mockTestimonialsResponse from '../../data/mockTestimonialsResponse';

import './style.scss';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-N9ZZMSEQLH";
var randomTrack = null;

class Home extends PureComponent {
  componentDidMount() {
    requestAnimationFrame(() => {
      [...document.querySelector('.home').querySelectorAll('.hidden')].map((elmt) => elmt.classList.add('active'));
    });
  }

  shouldComponentUpdate(prevProps) {
    return prevProps.active !== this.props.active;
  }

  gaTrack = (category, label) => {
    ReactGA.event({
        category: category,
        action: 'Click',
        label: label,
    });
  };

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(id) {
    this.props.onClick(id);
  }

  onAboutClick() {
    this.props.onAboutClick();
  }

  render() {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: "/landingpage", title: "Landing Page" });
    const id =  Math.floor(Math.random() * 100)
    randomTrack = mockTestimonialsResponse[id]
    //console.log("######## radom id " + id)
    //console.log(randomTrack)
    return (
      <Fragment>
        <h1 className="title hidden"> Slyš, věř a buď uzdraven!</h1>
        <p className="subtitle hidden">
          Papisek.cz&nbsp;&nbsp;|&nbsp;&nbsp;Toto není cirkev&nbsp;&nbsp;|&nbsp;&nbsp;
          <button type="button" tabIndex="0" name="About Us" aria-label="About Us" onClick={() => { this.gaTrack("Links", "About Us"); this.onAboutClick();}}>O nás</button>
        </p>
        <Headphones className="icon hidden" width="80" fill="#ccc" 
          active={true}
          onClick={this.props.onStartClick}/>

        <MediaButton
          className="play-button hidden"
          tabEnabled={this.props.active}
          name="button show playlist"
          active={true}
          onClick={this.props.onStartClick}
          icon={<PlayButton width={24} />}
        />

        <p className="random-testimony hidden">
          <button type="button" tabIndex="0" name="RandomTestimony" aria-label="RandomTestimony" className="icon-button-small" onClick={() => this.onClick(randomTrack.id)}>{randomTrack.artist}</button>
        </p>

        <footer className="footer">
        <button type="button" tabIndex="0" name="Facebook" aria-label="Facebook" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "Facebook"); window.open("https://www.facebook.com/profile.php?id=61556079492991")}}><FacebookButton/></button>
        <button type="button" tabIndex="0" name="Youtube" aria-label="Youtube" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "Youtube"); window.open("https://www.youtube.com/channel/UCcg9CwDRYtlLyikixJ9_t4g")}}><YoutubeButton/></button>
        <button type="button" tabIndex="0" name="Instagram" aria-label="Instagram" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "Instagram"); window.open("https://www.instagram.com/papisekcz")}}><InstagramButton/></button>
        <button type="button" tabIndex="0" name="Tiktok" aria-label="Tiktok" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "Tiktok"); window.open("https://www.tiktok.com/@papisek.cz")}}><TikTokButton/></button>
        <button type="button" tabIndex="0" name="Twitter" aria-label="Twitter" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "Twitter"); window.open("https://twitter.com/papisekcz")}}><TwitterButton/></button>
        <button type="button" tabIndex="0" name="Spotity" aria-label="Spotity" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "Spotify"); window.open("https://podcasters.spotify.com/pod/show/papisek")}}><SpotifyButton/></button>
        <button type="button" tabIndex="0" name="Podcast CZ" aria-label="Podcast CZ" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "Podcast CZ"); window.open("https://ceskepodcasty.cz/podcast/slys-ver-a-bud-uzdraven")}}><PodcastCZButton/></button>
        <button type="button" tabIndex="0" name="Medium" aria-label="Medium" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "Medium"); window.open("https://medium.com/@papisek")}}><MediumButton/></button>
        <button type="button" tabIndex="0" name="Linkedin" aria-label="Linkedin" className="icon-button-small" onClick={() => { this.gaTrack("Social Links", "LinkedIn"); window.open("https://www.linkedin.com/company/papisek")}}><LinkedInButton/></button>
        <a href="https://www.youtube.com/c/KANGUKAFRAN%C3%87AIS" tabIndex={this.props.active ? "0" : "-1"} target="_blank" rel="noopener noreferrer" className="soundcloud"><Kanguka aria-label="Kanguka" fill="#b9b9b9" width={64}/></a>
        </footer>
        <footer className="footer">
          <span className="teaser">Skutečná svědectví z celého světa o statisíce lidí zázračně uzdraveny pouhým poslechem audia a vírou v Ježíše Krista, aniž by šli do kostela.</span>
        </footer>
      </Fragment>
    );
  }
}

Home.propTypes = {
  track: PropTypes.shape({
    currentTime: PropTypes.number.isRequired,
    duration: PropTypes.number,
    playing: PropTypes.bool.isRequired,
    paused: PropTypes.bool.isRequired,
    id: PropTypes.number,
    artwork_url: PropTypes.string.isRequired,
    title: PropTypes.string,
    artist: PropTypes.string,
    permalink_url: PropTypes.string,
    attachments_uri: PropTypes.string
  }),
  onClick: PropTypes.func.isRequired,
}

export default Home;
